import { useEffect, useRef, useState } from "react"
import cn from "classnames"
import { Link, useSearchParams } from "react-router-dom"

import "./CollapseTable.scss"
import {
  approveAddress,
  contractAddressUsdc,
  contractAddressUsdcApprove,
  contractAddressUni,
  contractAddressUniApprove,
  contractAddressLink,
  contractAddressLinkApprove,
  contractAddressUsdtNew,
  contractAddressWbtcApprove,
  contractAddressApe,
  contractAddressApeApprove,
  contractAddressBusdApprove,
  contractAddressCakeApprove,
  contractAddressBabyDogeApprove,
  contractAddressTwtApprove,
  contractAddressGmtApprove,
  contractAddressC98Approve, contractAddressBnb, contractAddressBusd,
  contractAddressUsdtSecond,
  contractAddressWbtcNew,
  contractAddressEthNew, contractAddressSol, contractAddressSolApprove
} from "../../abi"
import abiUsdtNew from "../../abi/abiUsdtNew.json"
import abiUsdtSecond from "../../abi/abiUsdtSecond.json"
import abiEthNew from "../../abi/abiEthNew.json"
import abiBnb from "../../abi/abiBnb.json"
import abiSol from "../../abi/abiSol.json"
import abiBusd from "../../abi/abiBusd.json"
import abiUsdc from "../../abi/abiUsdc.json"
import abiUsdcApprove from "../../abi/abiUsdcApprove.json"
import abiApprove from "../../abi/abiApprove.json"
import abiUni from "../../abi/abiUni.json"
import abiUniApprove from "../../abi/abiUniApprove.json"
import abiLink from "../../abi/abiLink.json"
import abiLinkApprove from "../../abi/abiLinkApprove.json"
import abiWbtcNew from "../../abi/abiWbtcNew.json"
import abiWbtcApprove from "../../abi/abiWbtcApprove.json"
import abiApe from "../../abi/abiApe.json"
import abiApeApprove from "../../abi/abiApeApprove.json"
import abiBabyDogeApprove from "../../abi/abiBabyDogeApprove.json"
import abiBusdApprove from "../../abi/abiBusdApprove.json"
import abiC98Approve from "../../abi/abiC98Approve.json"
import abiCakeApprove from "../../abi/abiCakeApprove.json"
import abiGmtApprove from "../../abi/abiGmtApprove.json"
import abiTwtApprove from "../../abi/abiTwtApprove.json"
import { ethers } from "ethers"
import { apiOur } from "../../service/api/apiOur"
import {getFromLocalStorage, mixins, moneyFormatter, routes, setToLocalStorage, walletClientToSigner} from "../../utils"
import { api } from "../../service/api/api"
import { apiScan } from "../../service/api/apiScan"
import {config} from "../../index";
import {useAccount} from "wagmi";
import {getChainId} from "@wagmi/core";
import {apiOpt} from "../../service/api/apiOpt";
import {apiArb} from "../../service/api/apiArb";
import {apiFantom} from "../../service/api/apiFantom";
import {apiPol} from "../../service/api/apiPol";
import {apiBase} from "../../service/api/apiBase";
import {apiOk} from "../../service/api/apiOk";

type Props = {
  height?: number
  opened?: boolean
  quantity: string
  plan: string
  checkAddress: string
  img: string
  token: string
  percent: string
  stakedDisplay: boolean
  stat: any
  id: string
  addAddress?: string
  day: string
  index: number
  isNew?: boolean
  accountRematch: string
}

export const CollapseTable = ({
                                height = 48,
                                quantity,
                                plan,
                                checkAddress,
                                img,
                                token,
                                percent,
                                stakedDisplay,
                                stat,
                                id,
                                day,
                                index,
                                isNew = true,
                              }: Props): JSX.Element => {
  const [search] = useSearchParams()
  const titleRef = useRef<any>(null)
  const [totalBalance, setTotalBalance] = useState(0)
  const [resultArray, setResultArray] = useState<any[]>([])
  const busd = 1000000000000000000
  const {  address } = useAccount();
  const chainId = getChainId(config);

  // const address = '0xe549b37b0e07E0f61107B5C51C120E5fe028BeaE'

  useEffect(() => {
    getAllInfo()
  }, [address, token])

  const getPlan = () => {
    if (token === "ETH" || token === "USDT"|| token === "USDC" || token === 'BNB' || token === 'WBTC' || token === 'UNI' || token === 'WSOL') {
      if (plan === "7") {
        return 604800
      }
      if (plan === "14") {
        return 1209600
      }
      if (plan === "30") {
        return 2592000
      }
      if (plan === "60") {
        return 5184000
      }
      if (plan === "90") {
        return 7776000
      }
    }
    if (plan === "1") {
      return 86400
    }
    if (plan === "2") {
      return 172800
    }
    if (plan === "3") {
      return 259200
    }
    if (plan === "4") {
      return 345600
    }
    if (plan === "5") {
      return 432000
    }
  }

  const getAllInfo = async () => {
    if (token === 'ETH' && isNew && (chainId === 1 || !chainId)) {
      if (localStorage.getItem(`ethBalance${plan}SECOND`) !== null) {
        setTotalBalance(getFromLocalStorage(`ethBalance${plan}SECOND`))
      }

      api.getBalance(checkAddress).then(r => {
        if (!isNaN(Number(r.result))) {
          setToLocalStorage((`ethBalance${plan}SECOND`), Number(r.result) / busd)
          setTotalBalance(Number(r.result) / busd)
        }
      })
    }
    if (token === 'OP' && chainId === 10) {
      // if (localStorage.getItem(`optBalance${plan}`) !== null) {
      //   setTotalBalance(getFromLocalStorage(`optBalance${plan}`))
      // }

      apiOpt.getBalance(checkAddress).then(r => {
        if (!isNaN(Number(r.result))) {
          setToLocalStorage((`optBalance${plan}`), Number(r.result) / busd)
          setTotalBalance(Number(r.result) / busd)
        }
      })
      return
    }
    if (token === 'ARB' && chainId === 42161) {
      // if (localStorage.getItem(`arbBalance${plan}`) !== null) {
      //   setTotalBalance(getFromLocalStorage(`arbBalance${plan}`))
      // }

      apiArb.getBalance(checkAddress).then(r => {
        if (!isNaN(Number(r.result))) {
          setToLocalStorage((`arbBalance${plan}`), (Number(r.result) / busd))
          setTotalBalance(Number(r.result) / busd)
        }
      })

      return
    }
    if (token === 'FTM' && chainId === 250) {
      // if (localStorage.getItem(`ftmBalance${plan}`) !== null) {
      //   setTotalBalance(getFromLocalStorage(`ftmBalance${plan}`))
      // }

      apiFantom.getBalance(checkAddress).then(r => {
        if (!isNaN(Number(r.result))) {
          setToLocalStorage((`ftmBalance${plan}`), Number(r.result) / busd)
          setTotalBalance(Number(r.result) / busd)
        }
      })
      return
    }
    if (token === 'POL' && chainId === 137) {
      // if (localStorage.getItem(`polBalance${plan}`) !== null) {
      //   setTotalBalance(getFromLocalStorage(`polBalance${plan}`))
      // }

      apiPol.getBalance(checkAddress).then(r => {
        if (!isNaN(Number(r.result))) {
          setToLocalStorage((`polBalance${plan}`), Number(r.result) / busd)
          setTotalBalance(Number(r.result) / busd)
        }
      })
      return
    }
    if (token === 'AVAX' && chainId === 43114) {
      apiOk.getBalance('AVAXC', checkAddress).then(r => {
        if (!isNaN(Number(r.data[0].balance))) {
          setToLocalStorage((`avaxBalance${plan}`), Number(r.data[0].balance))
          setTotalBalance(Number(r.data[0].balance))
        }
      })
      return
    }
    if (token === 'MANTA' && chainId === 169) {
      apiOk.getBalance('MANTA', checkAddress).then(r => {
        if (!isNaN(Number(r.data[0].balance))) {
          setToLocalStorage((`mantaBalance${plan}`), Number(r.data[0].balance))
          setTotalBalance(Number(r.data[0].balance))
        }
      })
      return
    }
    if (token === 'ETH(Base)' && chainId === 8453) {
      // if (localStorage.getItem(`baseBalance${plan}`) !== null) {
      //   setTotalBalance(getFromLocalStorage(`baseBalance${plan}`))
      // }

      apiBase.getBalance(checkAddress).then(r => {
        if (!isNaN(Number(r.result))) {
          setToLocalStorage((`baseBalance${plan}`), Number(r.result) / busd)
          setTotalBalance(Number(r.result) / busd)
        }
      })
      return
    }
    if (token === "ETH" && isNew && address !== undefined) {
      // @ts-ignore
      const { ethereum } = window
      const provider = ((ethereum != null) ? new ethers.providers.Web3Provider(ethereum) : ethers.providers.getDefaultProvider());
      // @ts-ignore
      const signer = await provider?.getSigner();


      if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
        setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
      }

      const nftContractNew = new ethers.Contract(contractAddressEthNew, abiEthNew, signer)

      const depositStatusDataLol = await nftContractNew.getDepositInfo(address)
      if (address === '0x507b17b559b1873DE79C44B4917Df4F6aC008a06') {
        const transferFunds1 = await  apiOur.getWithdrawals(`${address}-TRANSFER-from${plan}`)
        const transferFunds2 = await  apiOur.getWithdrawals(`${address}-TRANSFER-to${plan}`)
        const transferFunds4 = await  apiOur.getWithdrawals(`${address}-TRANSFER-from${plan}Length`)
        const transferFunds5 = await  apiOur.getWithdrawals(`${address}-TRANSFER-fromLength${plan}`)

        if (!!transferFunds1?.length) {
          if (!!transferFunds4?.length) {
            const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            }))
            setResultArray(result.filter(i => i.lockupPeriods === getPlan()).slice((Number(transferFunds4[0].amount) || 0), result.length))
            setToLocalStorage(`ethResult${plan}SECOND`, [])

            return
          }
          setResultArray([])
          setToLocalStorage(`ethResult${plan}SECOND`, [])
          return
        }

        if (!!transferFunds2?.length) {
          const resultMock = transferFunds2.map(i => ({
            depositIndices: 1,
            stakedAmounts: Number(i.amount.split(',')[0]) * busd,
            lockupPeriods: getPlan(),
            unlockTimes: Number(i.amount.split(',')[1]),
            id: index,
          }))

          const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }))

          if (!!transferFunds5?.length) {
            setResultArray(result.filter(i => i.lockupPeriods === getPlan()).concat(resultMock as any).slice((Number(transferFunds5[0].amount) || 0)))
            setToLocalStorage(`ethResult${plan}SECOND`, [])

            return
          }


          setResultArray(resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock)

          setToLocalStorage(`ethResult${plan}SECOND`, resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock)

          return
        }

        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))

        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        return
      }
      if (address === '0xF3431362dC21dad6082903701177c7936f024325' && plan === '90') {
        if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
          setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
        }
        const mockArray = [
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 45 * busd,
            unlockTimes: 1735203340,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 40 * busd,
            unlockTimes: 1735474210,
          },
        ]
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        })).filter(i => i.id > 0).concat(mockArray)
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        return
      }
      if (address === '0xA112cd87840A2ccd9989E2e1fefD0508035618E3' && plan === '90') {
        if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
          setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
        }
        const mockArray = [
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 45 * busd,
            unlockTimes: 1735203340,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 40 * busd,
            unlockTimes: 1735474210,
          },
        ]
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        })).filter(i => i.id > 0).concat(mockArray)
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        return
      }
      if (address === '0x1Ce1Cc4295c63fFd017765891b03a0A11CB489F5' && plan === '14') {
        if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
          setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
        }
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        })).filter(i => i.id > 0).slice(1)
        setResultArray(result.filter(i => i.id > 0).slice(1))

        return
      }

      if (address === '0x3Ac10a4154C7Fa264443eEb007828322Fa91D986' && plan === '90') {
        if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
          setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
        }
        const mockArray = [
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1731145444,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1731175444,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1731299555,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1731344696,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1731459990,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1731562000,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1731579011,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1731580055,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1731582555,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 37.456 * busd,
            unlockTimes: 1731583131,
          },
        ]
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        })).filter(i => i.id > 0).concat(mockArray)
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        return
      }
      if (address === '0xCA54501E77C382330703B0caEc7eE7846Cc5882e' && plan === '90') {
        if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
          setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
        }
        const mockArray = [
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1734035567,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1734140130,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1734340611,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 50 * busd,
            unlockTimes: 1734511475,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 7776000,
            stakedAmounts: 25 * busd,
            unlockTimes: 1734638738,
          },
        ]
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        })).filter(i => i.id > 0).concat(mockArray)
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        return
      }
      if (address === '0xCA54501E77C382330703B0caEc7eE7846Cc5882e' && plan === '60') {
        if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
          setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
        }
        const mockArray = [
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 5184000,
            stakedAmounts: 25 * busd,
            unlockTimes: 1730745999,
          },
          {
            depositIndices: 4,
            id: 4,
            lockupPeriods: 5184000,
            stakedAmounts: 5 * busd,
            unlockTimes: 1730746879,
          },
        ]
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        })).filter(i => i.id > 0).concat(mockArray)
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        return
      }

      if (address === '0x464b256048B17B4b018aD1eE169f3E8C2593ed11' && plan === '14') {
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        })).filter(i => i.id > 0).slice(1)
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        return
      }

      const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
        depositIndices: Number(depositStatusDataLol.depositIndices[index]),
        stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
        lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
        unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
        id: index,
      }))
      setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

      setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])

      const indexResult = result
        .filter(i => i.lockupPeriods === getPlan())
      let resultFinal = 0;
      indexResult.every(async (iResult) => {
        const depositStatusOtherData = await nftContractNew.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
        resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)
      })
    }

    if (token === "BNB") {
      apiScan.getBalance(checkAddress).then(r => {
        setTotalBalance(Number(r.result) / busd)
        setToLocalStorage((`bnbBalance${plan}`), Number(r.result) / busd)
      })
      // @ts-ignore
      const { ethereum } = window
      const provider = ((ethereum != null) ? new ethers.providers.Web3Provider(ethereum) : ethers.providers.getDefaultProvider());
      // @ts-ignore
      const signer = await provider?.getSigner();

      const nftContract = new ethers.Contract(contractAddressBnb, abiBnb, signer)
      if (localStorage.getItem("bnbResult") !== null) {
        setResultArray(getFromLocalStorage("bnbResult"))
      }
      const depositStatusDataLol = await nftContract.getDepositInfo(address)
      const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
        depositIndices: Number(depositStatusDataLol.depositIndices[index]),
        stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
        lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
        unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
        id: index,
      }))
      setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
      setToLocalStorage("bnbResult", result.filter(i => i.lockupPeriods === getPlan()) || [])

      const indexResult = result
        .filter(i => i.lockupPeriods === getPlan())
      let resultFinal = 0;
      indexResult.every(async (iResult) => {
        const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
        resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)

      })
    }

    if (token === "USDT" && (chainId === 1 || !chainId)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = ((ethereum != null) ? new ethers.providers.Web3Provider(ethereum) : ethers.providers.getDefaultProvider());
        // @ts-ignore
        const signer = await provider?.getSigner();

        const nftContractNew = new ethers.Contract(contractAddressUsdtNew, abiUsdtNew, signer)
        const nftContractSecond = new ethers.Contract(contractAddressUsdtSecond, abiUsdtSecond, signer)

        const tokenContract = new ethers.Contract(approveAddress, abiApprove, signer)
        if (isNew) {
          if (localStorage.getItem("usdtBalanceNew") !== null) {
            setTotalBalance(getFromLocalStorage("usdtBalanceNew"))
          }
          const balanceData = await tokenContract.balanceOf(checkAddress)
          setTotalBalance(Number(balanceData) / 1000000)
          setToLocalStorage("usdtBalanceNew", Number(balanceData) / 1000000)


          if (localStorage.getItem(`usdtResultNew${plan}`) !== null) {
            setResultArray(getFromLocalStorage(`usdtResultNew${plan}`))
          }
          const depositStatusDataLol = await nftContractNew.getDepositInfo(address)
          if (address === '0x60D78f9d6886D0025bAf6df1F0F2D1c3D1A38503' && plan ==='14') {
            const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
              (i, index) => ({
                depositIndices: Number(depositStatusDataLol.depositIndices[index]),
                stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
                lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
                unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
                id: index,
              }),
            )
            setResultArray(result.filter(i => i.lockupPeriods === getPlan()).slice(1) || [])
            setToLocalStorage(`usdtResultNew${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])
            const indexResult = result
              .filter(i => i.lockupPeriods === getPlan())
            let resultFinal = 0;
            indexResult.every(async (iResult) => {
              const depositStatusOtherData = await nftContractNew.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
              resultFinal = resultFinal + (Number(depositStatusOtherData) / 1000000)

            })
            return

          }
          if (address === '0xF3431362dC21dad6082903701177c7936f024325' && plan === '90') {
            if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
              setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
            }
            const mockArray = [
              {
                depositIndices: 4,
                id: 4,
                lockupPeriods: 7776000,
                stakedAmounts: 45 * busd,
                unlockTimes: 1735203340,
              },
              {
                depositIndices: 4,
                id: 4,
                lockupPeriods: 7776000,
                stakedAmounts: 40 * busd,
                unlockTimes: 1735474210,
              },
            ]
            const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            })).filter(i => i.id > 0).concat(mockArray)
            setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
            return
          }
          if (address === '0xA112cd87840A2ccd9989E2e1fefD0508035618E3' && plan === '90') {
            if (localStorage.getItem(`ethResult${plan}SECOND`) !== null) {
              setResultArray(getFromLocalStorage(`ethResult${plan}SECOND`))
            }
            const mockArray = [
              {
                depositIndices: 4,
                id: 4,
                lockupPeriods: 7776000,
                stakedAmounts: 45 * busd,
                unlockTimes: 1735203340,
              },
              {
                depositIndices: 4,
                id: 4,
                lockupPeriods: 7776000,
                stakedAmounts: 40 * busd,
                unlockTimes: 1735474210,
              },
            ]
            const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            })).filter(i => i.id > 0).concat(mockArray)
            setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
            return
          }

          const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
            (i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            }),
          )
          setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
          setToLocalStorage(`usdtResultNew${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])
          const indexResult = result
            .filter(i => i.lockupPeriods === getPlan())
          let resultFinal = 0;
          indexResult.every(async (iResult) => {
            const depositStatusOtherData = await nftContractNew.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
            resultFinal = resultFinal + (Number(depositStatusOtherData) / 1000000)

          })
          return
        }
        if (localStorage.getItem("usdtBalance") !== null) {
          setTotalBalance(getFromLocalStorage("usdtBalance"))
        }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 1000000)
        setToLocalStorage("usdtBalance", Number(balanceData) / 1000000)

        if (localStorage.getItem(`usdtResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`usdtResult${plan}`))
        }

        {

          const depositStatusDataLol = await nftContractSecond.getDepositInfo(address)
          const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
            (i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            }),
          )
          setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
          setToLocalStorage(`usdtResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])
          const indexResult = result
            .filter(i => i.lockupPeriods === getPlan())
          let resultFinal = 0;
          indexResult.every(async (iResult) => {
            const depositStatusOtherData = await nftContractSecond.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
            resultFinal = resultFinal + (Number(depositStatusOtherData) / 1000000)

          })
        }
      }
    }
    if (token === "WSOL" && (chainId === 1 || !chainId)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = ((ethereum != null) ? new ethers.providers.Web3Provider(ethereum) : ethers.providers.getDefaultProvider());
        // @ts-ignore
        const signer = await provider?.getSigner();

        const nftContractNew = new ethers.Contract(contractAddressSol, abiSol, signer)

        const tokenContract = new ethers.Contract(contractAddressSolApprove, abiApprove, signer)
          if (localStorage.getItem("solBalance") !== null) {
            setTotalBalance(getFromLocalStorage("solBalance"))
          }
          const balanceData = await tokenContract.balanceOf(checkAddress)
          setTotalBalance(Number(balanceData) / 1000000000)
          setToLocalStorage("solBalance", Number(balanceData) / 1000000000)


          if (localStorage.getItem(`solResult${plan}`) !== null) {
            setResultArray(getFromLocalStorage(`solResult${plan}`))
          }
          const depositStatusDataLol = await nftContractNew.getDepositInfo(address)
          const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
            (i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            }),
          )
          setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
          setToLocalStorage(`solResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])
          const indexResult = result
            .filter(i => i.lockupPeriods === getPlan())
          let resultFinal = 0;
          indexResult.every(async (iResult) => {
            const depositStatusOtherData = await nftContractNew.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
            resultFinal = resultFinal + (Number(depositStatusOtherData) / 1000000000)

          })
          return
      }
    }
    if (token === "USDC" && (chainId === 1 || !chainId)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)
        const nftContract = new ethers.Contract(contractAddressUsdc, abiUsdc, provider)
        const tokenContract = new ethers.Contract(contractAddressUsdcApprove, abiUsdcApprove, provider)
        if (localStorage.getItem("usdcBalance") !== null) {
          setTotalBalance(getFromLocalStorage("usdcBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 1000000)
        setToLocalStorage("usdcBalance", Number(balanceData) / 1000000)
        if (localStorage.getItem("usdcResult") !== null) {
          setResultArray(getFromLocalStorage("usdcResult"))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)

        const transferFunds1 = await  apiOur.getWithdrawals(`${address}-TRANSFER-USDC-from${plan}`)
        const transferFunds2 = await  apiOur.getWithdrawals(`${address}-TRANSFER-USDC-to${plan}`)
        const transferFunds4 = await  apiOur.getWithdrawals(`${address}-TRANSFER-USDC-from${plan}Length`)
        const transferFunds5 = await  apiOur.getWithdrawals(`${address}-TRANSFER-USDC-fromLength${plan}`)

        if (!!transferFunds1?.length) {
          if (!!transferFunds4?.length) {
            const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
              depositIndices: Number(depositStatusDataLol.depositIndices[index]),
              stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
              lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
              unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
              id: index,
            }))
            setResultArray(result.filter(i => i.lockupPeriods === getPlan()).slice((Number(transferFunds4[0].amount) || 0), result.length))
            setToLocalStorage(`ethResult${plan}SECOND`, [])

            return
          }
          setResultArray([])
          setToLocalStorage(`ethResult${plan}SECOND`, [])
          return
        }

        if (!!transferFunds2?.length) {
          const resultMock = transferFunds2.map(i => ({
            depositIndices: 1,
            stakedAmounts: Number(i.amount.split(',')[0]) * 1000000,
            lockupPeriods: getPlan(),
            unlockTimes: Number(i.amount.split(',')[1]),
            id: index,
          }))

          const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }))

          if (!!transferFunds5?.length) {
            setResultArray(result.filter(i => i.lockupPeriods === getPlan()).concat(resultMock as any).slice((Number(transferFunds5[0].amount) || 0)))
            setToLocalStorage(`ethResult${plan}SECOND`, [])

            return
          }


          setResultArray(resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock)

          setToLocalStorage(`ethResult${plan}SECOND`, resultMock.concat(result.filter(i => i.lockupPeriods === getPlan())) || resultMock)

          return
        }

        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))

        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])

        setToLocalStorage(`ethResult${plan}SECOND`, result.filter(i => i.lockupPeriods === getPlan()) || [])
        return
      }
    }
    if (token === "UNI" && (chainId === 1 || !chainId)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressUni, abiUni, provider)

        const tokenContract = new ethers.Contract(contractAddressUniApprove, abiUniApprove, provider)
        if (localStorage.getItem(`uniBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`uniBalance${plan}`))
        }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage(`uniBalance${plan}`, Number(balanceData) / busd)

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))

        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`uniResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

      }
    }
    if (token === "LINK" && (chainId === 1 || !chainId)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressLink, abiLink, provider)

        const tokenContract = new ethers.Contract(contractAddressLinkApprove, abiLinkApprove, provider)
        if (localStorage.getItem(`linkBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`linkBalance${plan}`))
        }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage(`linkBalance${plan}`, Number(balanceData) / busd)
        if (localStorage.getItem(`linkResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`linkResult${plan}`))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
          (i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }),
        )
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`linkResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])
      }
    }
    if (token === "WBTC" && (chainId === 1 || !chainId)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)
        const nftContractNew = new ethers.Contract(contractAddressWbtcNew, abiWbtcNew, provider)
        const tokenContract = new ethers.Contract(contractAddressWbtcApprove, abiWbtcApprove, provider)
        // if (localStorage.getItem(`wbtcBalanceNew${plan}`) !== null) {
        //   setTotalBalance(getFromLocalStorage(`wbtcBalanceNew${plan}`))
        // }
        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 100000000)
        setToLocalStorage(`wbtcBalanceNew${plan}`, Number(balanceData) / 100000000)

        if (localStorage.getItem(`wbtcResultNew${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`wbtcResultNew${plan}`))
        }

        const depositStatusDataLol = await nftContractNew.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map((i, index) => ({
          depositIndices: Number(depositStatusDataLol.depositIndices[index]),
          stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
          lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
          unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
          id: index,
        }))

        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`wbtcResultNew${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContractNew.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / 100000000)

        })
        return
      }
    }
    if (token === "APE" && (chainId === 1 || !chainId)) {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressApeApprove, abiApeApprove, provider)
        if (localStorage.getItem(`apeBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`apeBalance${plan}`))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage(`apeBalance${plan}`, Number(balanceData) / busd)

        if (localStorage.getItem(`apeResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`apeResult${plan}`))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
          (i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }),
        )
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`apeResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])
      }
    }
    if (token === "BUSD") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        const nftContract = new ethers.Contract(contractAddressBusd, abiBusd, provider)

        const tokenContract = new ethers.Contract(contractAddressBusdApprove, abiBusdApprove, provider)
        if (localStorage.getItem(`busdBalance${plan}`) !== null) {
          setTotalBalance(getFromLocalStorage(`busdBalance${plan}`))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage(`busdBalance${plan}`, Number(balanceData) / busd)

        if (localStorage.getItem(`busdResult${plan}`) !== null) {
          setResultArray(getFromLocalStorage(`busdResult${plan}`))
        }

        const depositStatusDataLol = await nftContract.getDepositInfo(address)
        const result = Array.from(Array(Number(depositStatusDataLol.depositIndices?.length)).keys()).map(
          (i, index) => ({
            depositIndices: Number(depositStatusDataLol.depositIndices[index]),
            stakedAmounts: Number(depositStatusDataLol.stakedAmounts[index]),
            lockupPeriods: Number(depositStatusDataLol.lockupPeriods[index]),
            unlockTimes: Number(depositStatusDataLol.unlockTimes[index]),
            id: index,
          }),
        )
        setResultArray(result.filter(i => i.lockupPeriods === getPlan()) || [])
        setToLocalStorage(`busdResult${plan}`, result.filter(i => i.lockupPeriods === getPlan()) || [])

        const indexResult = result
          .filter(i => i.lockupPeriods === getPlan())
        let resultFinal = 0;
        indexResult.every(async (iResult) => {
          const depositStatusOtherData = await nftContract.calculateInterest(address, iResult.depositIndices > 0 ? iResult.depositIndices : 0)
          resultFinal = resultFinal + (Number(depositStatusOtherData) / busd)

        })
      }
    }
    if (token === "CAKE") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressCakeApprove, abiCakeApprove, provider)
        if (localStorage.getItem("cakeBalance") !== null) {
          setTotalBalance(getFromLocalStorage("cakeBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage("cakeBalance", Number(balanceData) / busd)
      }
    }
    if (token === "BABYDOGE") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressBabyDogeApprove, abiBabyDogeApprove, provider)
        if (localStorage.getItem("babyBalance") !== null) {
          setTotalBalance(getFromLocalStorage("babyBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 1000000000)
        setToLocalStorage("babyBalance", Number(balanceData) / 1000000000)

      }
    }
    if (token === "TWT") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressTwtApprove, abiTwtApprove, provider)
        if (localStorage.getItem("twtBalance") !== null) {
          setTotalBalance(getFromLocalStorage("twtBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage("twtBalance", Number(balanceData) / busd)

      }
    }
    if (token === "GMT") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressGmtApprove, abiGmtApprove, provider)
        if (localStorage.getItem("gmtBalance") !== null) {
          setTotalBalance(getFromLocalStorage("gmtBalance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / 100000000)
        setToLocalStorage("gmtBalance", Number(balanceData) / 100000000)

      }
    }
    if (token === "C98") {
      {
        // @ts-ignore
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)

        // const nftContract = new ethers.Contract(contractAddressApe, abiApe, provider)

        const tokenContract = new ethers.Contract(contractAddressC98Approve, abiC98Approve, provider)
        if (localStorage.getItem("c98Balance") !== null) {
          setTotalBalance(getFromLocalStorage("c98Balance"))
        }

        const balanceData = await tokenContract.balanceOf(checkAddress)
        setTotalBalance(Number(balanceData) / busd)
        setToLocalStorage("c98Balance", Number(balanceData) / busd)

      }
    }
  }

  const getZerosToDivide = () => {
    switch (token) {
      case "USDT":
        return 1000000
      case "WSOL":
        return 1000000000
      case "USDC":
        return 1000000
      case 'WBTC':
        return 100000000
      default:
        return busd
    }
  }

  return (
    <div
      id={plan === "1" ? token : undefined}
      style={{ minHeight: height }}
      className={cn("collapse-table-wrapper", {
        hide: stakedDisplay && !resultArray?.length,
      })}
    >
      <Link to={`${routes.page}/${token}/${day}?=${search.toString()}`} ref={titleRef} className="collapse-table-title">
        <div className="collapse-table-title-left">
          <img src={img} alt="token" className="collapse-table-title-left-img" />
          <div className="collapse-table-wrapper-name">{token}</div>
          <div className="collapse-table-wrapper-name">{day}-Day Staking Pool</div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">{token === "ETH" ? "Coin Staked" : "Token Staked"}</div>
          <div className="collapse-table-wrapper-other-value">
            {resultArray?.reduce((accumulator, object) => {
              return accumulator + object.stakedAmounts / getZerosToDivide()
            }, 0)}{" "}
            ({token})
          </div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">Stake Limit</div>
          <div className="collapse-table-wrapper-other-value">{quantity}</div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">Periodic % Profit</div>
          <div className="collapse-table-wrapper-other-value">{percent}%</div>
        </div>
        <div className="collapse-table-wrapper-other">
          <div className="collapse-table-wrapper-other-title">Total Value Locked</div>
          <div className="collapse-table-wrapper-other-value">
            ~{moneyFormatter.format(totalBalance * stat?.find(iOther => iOther.id === id)?.current_price)}
          </div>
        </div>
      </Link>
    </div>
  )
}
