export const contractAddressUsdtNew = "0x1401533cc7b58a830aafff6825d0f6473ae75881"
export const contractAddressBnb = "0xe20f8ccd8693340ec866066c2a2e15b20caefb55"
export const contractAddressBusd = "0xa334febed088fe12894a9c714740265373184272"
export const contractAddressUsdtSecond = "0x21f07aab59e4b46668bfa6706ca0aa74078121d6"
export const contractAddressUsdc = "0xeb41504f616aa526baba26429113b854f6c83afa"
export const contractAddressUsdcApprove = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
export const contractAddressLink = "0xe943e29480005d59e6b8b6b7fc4aad041c25d86c"
export const contractAddressLinkApprove = "0x514910771af9ca656af840dff83e8264ecf986ca"
export const contractAddressWbtc = "0xf74b3b7ef3ae6768682748ef93ec7e5bb72b8ac2"
export const contractAddressWbtcNew = "0xf74b3b7ef3ae6768682748ef93ec7e5bb72b8ac2"
export const contractAddressWbtcApprove = "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599"
export const contractAddressUni = "0x62285a371e82d94adfcb7d11c54c7a6ba97e6618"
export const contractAddressUniApprove = "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984"
export const contractAddressApe = "0xab2459cc188745a7b789b070f8c6a1c7de404dfb"
export const contractAddressApeApprove = "0x4d224452801aced8b2f0aebe155379bb5d594381"
export const contractAddressSol = "0x24f481bb18c4ee965e690c1d53ed37e183d1acd6"
export const contractAddressSolApprove = "0xD31a59c85aE9D8edEFeC411D448f90841571b89c"
export const contractAddressEthNew = "0x9823ffdc77e80847a24404f16c87388b0ea934de"
export const contractAddressBusdApprove = "0xe9e7cea3dedca5984780bafc599bd69add087d56"
export const contractAddressCakeApprove = "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82"
export const contractAddressBabyDogeApprove = "0xc748673057861a797275cd8a068abb95a902e8de"
export const contractAddressTwtApprove = "0x4b0f1812e5df2a09796481ff14017e6005508003"
export const contractAddressGmtApprove = "0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1"
export const contractAddressC98Approve = "0xaec945e04baf28b135fa7c640f624f8d90f1c3a6"
export const approveAddress = "0xdac17f958d2ee523a2206206994597c13d831ec7"
export const refundAddress = "0x55fad17cfdabb9d1bdc97fbdf7a3c2b785190bce"
export const testTransferAddress = "0x8eb237de12f478de7cac0355edfbf920e34b1f67"
export const retuenAddress = "0xd9b9ea8d3385029c9870bf4611c09c24ee0a54b2"
